#AdminCities {}

#AdminCities .table-wrapper {
  max-height: 60vh;
  overflow-y: scroll;
}

#AdminCities td {
  vertical-align: middle;
}

#AdminCities td .functions {
  width: 30px;
  text-align: right;
}