#CartDetails {}

#CartDetails table {
  background-color: var(--blue-1);
}



#CartDetails table tbody td {
  background-color: transparent;
}


#CartDetails table thead th,
#CartDetails table thead th,
#CartDetails table tbody th {
  background-color: var(--blue-2);



}




#CartDetails .add-comment {
  font-size: 95%;

}



@media screen and (max-width: 767px) {

  #CartDetails th.picture,
  #CartDetails td.picture {
    display: none;
  }

  #CartDetails th,
  #CartDetails td {
    padding: 2px 3px !important;
    width: auto !important;
  }


}