#AdminCustomerOrders {}



.modal #AdminCustomer .tab-contents {
  height: 70vh;
}


#AdminCustomerOrders div.slider {
  width: 200%;
  transition: all 700ms;

}

#AdminCustomerOrders div.slider>div {
  max-height: 65vh;
  overflow-y: auto;
}