.main-layout .content {
  border: 0px solid pink
}

#default-layout {}

body.standalone #main-layout .content {
  padding: 120px 0 200px;
}


body.embeded #main-layout .content {
  padding-top: 0px !important;

  padding-bottom: 50px !important;

}