#order {}





#order section {
  margin-bottom: 50px;

}


#order .labels {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}

#order label.select {
  position: relative;
  display: flex;
  line-height: 1.3;
  padding: 10px;
  text-align: center;
  border-radius: 15px;
  flex-direction: column;
  user-select: none;
  cursor: pointer;
  background-size: cover;
  color: black;
  height: 70px;
  justify-content: center;
}

#order label.select strong {
  display: block;
}


#order .collecte label.select {
  background-image: url('https://laffairearepasser.fr/msmedias/petales/petale-bleu-uni.png');
}

#order .restitution label.select {
  background-image: url('https://laffairearepasser.fr/msmedias/petales/petale-vert-uni.png');
}


#order label .check {
  position: absolute;
  right: 10px;
  top: 20px;

}




#order label.select img {
  max-height: 35px;
  margin-bottom: 0.5rem;
}

#order label.select input {
  display: none;
}



#order ul.categorie-products {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

#order ul.categorie-products li {
  flex: 0 0 180px;
  border: 1px solid #ddd;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  padding: 7px;
  margin: 0 10px 10px 0;
  font-size: 0.8rem;
}

#order ul.categorie-products .input-group {
  max-width: 120px;
  margin: 2px auto 0;
}



@media screen and (min-width: 576px) {
  #order label.select {
    flex: 0 0 165px;
    width: 165px;
    height: 84px;
    margin: 0 30px 30px 0;
    padding-right: 30px;
  }

}


@media screen and (max-width: 575px) {
  #order .labels {
    justify-content: space-between;
  }

  #order label.select {
    flex: 0 0 150px;
    width: 150px;
    height: 77px;
    margin: 0 10px 15px 0;
  }

  #order label.select svg {
    max-width: 25px;
  }

  #order .buttons-section {
    text-align: center;
  }
}