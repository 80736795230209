#default-layout {}

body.standalone #default-layout .content {
  padding: 120px 0 200px;
}


body.embeded #default-layout .content {
  padding-top: 0px !important;

  padding-bottom: 50px !important;

}