.switcher {
  width: 36px;
  height: 18px;
  border-radius: 24px;
  border: 1px solid #999;
  position: relative;
  transition: all 200ms;
  background-color: #eee;
  overflow: hidden;
  cursor: pointer;
}

.switcher.disabled {
  cursor: no-drop;
}

.switcher .handler {
  display: inline-block;
  position: absolute;
  height: 16px;
  width: 16px;
  left: 50%;
  transform: translateX(-50%);
  top: 1;
  transition: left 200ms;
  background-color: #333;
  border-radius: 24px;
}

.switcher.off .handler {
  left: 0;
  transform: translateX(0);

}

.switcher.on .handler {
  left: 100%;
  transform: translateX(-100%);
  background-color: var(--active);

}



.switcher-sm {
  width: 24px;
  height: 12px;
  border-radius: 24px;
}

.switcher-sm .handler {
  height: 10px;
  width: 10px;
}