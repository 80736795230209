#AdminPickupOccurrence .toolbar {
  background-color: #ddd;
  border-radius: 7px;
}

#AdminPickupOccurrence .switcher.on .handler {
  background-color: var(--bs-danger);
}

#AdminPickupOccurrence tbody td {
  font-size: 0.8rem
}

#AdminPickupOccurrence .flow-label {
  user-select: none;
}

#AdminPickupOccurrence .flow-label svg {
  position: relative;
  top: -2px;
}