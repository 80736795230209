#admin-pickups-calendar-v2 {}

#admin-pickups-calendar .occurrence * {
  line-height: 1 !important;
}

#admin-pickups-calendar-v2 .fc .fc-button {
  padding: 0 0;
}

#admin-pickups-calendar-v2 .fc-h-event {
  font-style: normal;
  background-color: transparent;
  border: none;
  font-size: 0.7rem;
  height: auto !important;
  border-bottom: 0px solid #eee;
  border-radius: 0;
}

#admin-pickups-calendar-v2 .fc-h-event .fc-event-main {
  color: black;
}

#admin-pickups-calendar-v2 .fc-daygrid-week-number {
  rotate: 270deg;
  top: 50% !important;
  left: -10px !important;
  line-height: 1px !important;
  padding: 0 !important;
}

#admin-pickups-calendar-v2 .fc .fc-daygrid-day-frame {
  max-height: 35px;
}

#admin-pickups-calendar-v2 .fc-day-today {
  background-color: rgba(255, 232, 80, 0.3);
}

#admin-pickups-calendar-v2 .fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {}

#admin-pickups-calendar-v2 .red-circle {
  height: 20px;
  width: 20px;
  margin: 0 auto;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: red;
  border: 1px solid white;
  color: white;
  border-radius: 50%;
}

#admin-pickups-calendar-v2 .fc-day-today .fc-daygrid-day-number {
  font-weight: bold;
  background-color: transparent !important;
}

#admin-pickups-calendar-v2 .fc-toolbar-chunk h2 {
  font-size: 1rem;
}

#admin-pickups-calendar-v2 .fc-event-main {
  text-align: center;
}

#admin-pickups-calendar-v2 .switcher-on .handler {
  background-color: var(--bs-danger);
}

#admin-pickups-calendar-v2 .flow-icon {
  position: relative;
  top: 7px;
  padding-left: 40px;
  /* Ajuste cet espace pour éloigner le texte du rond */
}

#admin-pickups-calendar-v2 .flow-icon::before {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
}

#admin-pickups-calendar-v2 .flow-icon.collecte::before {
  content: "C";
  background-color: #046fb5;
  color: white;
}

#admin-pickups-calendar-v2 .flow-icon.restitution::before {
  content: "R";
  background-color: #899b41;
  color: white;
}

#admin-pickups-calendar-v2 tbody th {
  background-color: #eee;
}

#admin-pickups-calendar-v2 tbody svg {
  position: relative;
  top: -2px;
}

#admin-pickups-calendar-v2 .order-canceled td {
  opacity: 0.4;
  background-color: #eee;
}

#admin-pickups-calendar-v2 .fc-customVersionButton-button {
  background-color: transparent;
  color: #999;
  border: none;
  border-radius: 0;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 12px;
}