#AdminPickups .switcher .handler {
  background-color: white;
}

#AdminPickups .switcher-on {
  background-color: #c92a2a;
}

#AdminPickups .switcher-off {
  background-color: #484848;
}


.admin-pickup-modal {}