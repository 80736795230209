#AdminCatalog {}


#AdminCatalog .categories-tree {
  background-color: #fafafa;
}

#AdminCatalog .categories-tree ul {
  list-style: none;
  margin: 0 0;
  padding: 0 0;
}

#AdminCatalog .categories-tree li {
  margin: 0 0;
  padding: 0 0;
}

#AdminCatalog .categories-tree li>div:hover {
  background-color: #f0f0f0;
}

#AdminCatalog input:read-only {
  background-color: transparent;
  border: none;
}

#AdminCatalog .categories-tree>ul>li {
  border-bottom: 1px solid white;
}

#AdminCatalog .categories-tree>ul>li:last-child {
  border-bottom: none;
}

#AdminCatalog .categories-tree ul ul {
  font-size: 90%
}

#AdminCatalog .drag-handle {
  display: inline-block;
  text-align: left;
  width: 30px;

}

#AdminCatalog .fs-3 {
  font-size: 1rem !important;
}


#AdminCatalog .fs-4 {
  font-size: 0.9rem !important;
}

#AdminCatalog .fs-5 {
  font-size: 0.8rem !important;
}

#AdminCatalog .fs-6 {
  font-size: 0.7rem !important;
}