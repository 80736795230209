#AdminPickupModalOccurrences .switcher .handler {
  background-color: white;
}

#AdminPickupModalOccurrences .switcher.on {
  background-color: rgba(var(--bs-danger-rgb));
}

#AdminPickupModalOccurrences .switcher.off {
  background-color: rgba(var(--bs-secondary-rgb));
}

#AdminPickupModalOccurrences .inactive {
  opacity: 0.4;
}

.admin-pickup-modal .modal-body.occurrences .tab-contents {
  padding-top: 0;
}