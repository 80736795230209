@keyframes swal2-show {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

#swal2-html-container {
  padding: 36px 0 0 0 !important;
  margin: 0 25px 25px !important;
  user-select: text;

}

div.swal2-actions {
  margin: 0em auto 0 !important;
}

div.swal2-modal {
  padding: 10px 15px;
}


.swal2-confirm {
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
  color: #000 !important;
}


.swal2-confirm:hover,
.swal2-confirm:focus,
.swal2-confirm:active {
  background-color: #ffca2c !important;
  border-color: #ffc720 !important;
}