.admin-pickups-calendar {}

.admin-pickups-calendar .occurrence * {
  line-height: 1 !important;
}

.admin-pickups-calendar .fc-h-event {
  font-style: normal;
  background-color: transparent;
  border: none;
  font-size: 0.7rem;
  height: auto !important;
  border-bottom: 0px solid #eee;
  border-radius: 0;
}

.admin-pickups-calendar .fc-h-event .fc-event-main {
  color: black;
}

.admin-pickups-calendar .switcher-on .handler {
  background-color: var(--bs-danger);
}

.admin-pickups-calendar .collect,
.admin-pickups-calendar .restitution {
  padding: 1px 0;
  width: 18px;
  text-align: center;
  display: inline-block;
}

.admin-pickups-calendar .collect {
  background-color: #046FB5;
  color: white;
}

.admin-pickups-calendar .restitution {
  background-color: #D9F371;
}


.admin-pickups-calendar .fc-customVersionButton-button {
  background-color: transparent;
  /* Couleur de fond personnalisée */
  color: #999;
  /* Couleur du texte */
  border: none;
  /* Supprime la bordure par défaut */
  border-radius: 0;
  /* Coins arrondis */
  padding: 5px 10px;
  /* Espacement interne */
  cursor: pointer;
  /* Curseur en forme de main */
  font-size: 12px;
  /* Taille de la police */

}