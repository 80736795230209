.modal-order-products-list {}


#OrderProductList {
  user-select: none;
  width: 96% !important;
  max-width: 650px !important;
}

@media screen and (max-width: 768px) {}



#OrderProductList .modal-content {
  display: flex;
}


#OrderProductList .modal-content {
  display: flex;
  margin-top: -30px;
}


#OrderProductList .modal-header,
#OrderProductList .modal-footer {
  flex: 0 0;
}





.modal-order-products-list .modal-body {

  max-height: 75vh;
  /* height: 80vh; */
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  overflow: hidden;
}

#OrderProductList .wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: 200%;
  height: 100%;
  align-items: flex-start;


  overflow-x: hidden;
}


#OrderProductList ul,
#OrderProductList li {
  list-style: none;
  margin: 0px;
  padding: 0;
}

#OrderProductList h5.cat-name {
  border-radius: 7px;
  background-color: var(--blue-1);
  color: var(--blue);
  border-color: var(--blue);
}

#OrderProductList .slide {
  flex: 0 0 100%;
  width: 100%;
  margin-left: 0;
  transition: margin-left 500ms;
  max-height: 100%;
  overflow-y: auto;
  padding-right: var(--bs-modal-padding);
}

#OrderProductList ul.catalog-items {
  display: flex;
  flex-wrap: wrap;
}

#OrderProductList li.catalog-item {
  border: 1px solid #ddd;
  border-radius: 7px;
  text-align: center;
  padding: 7px;
  margin: 0 10px 10px 0;
  font-size: 0.8rem;
}

#OrderProductList li .top * {
  font-size: 0.8rem;
  line-height: 1.2;
}


#OrderProductList h5 {
  font-size: 0.9rem;
}



#OrderProductList ul.subcategories {}


#OrderProductList ul.subcategories>li {
  flex: 0 0 calc(25% - 10px);
}


#OrderProductList h6.subcat-name {
  font-size: 0.8rem;
}


#OrderProductList ul.products>li {
  flex: 0 0 calc(50% - 10px);
}


#OrderProductList .cover {
  position: relative;
  overflow: hidden;


}


#OrderProductList ul.subcategories .cover {
  width: 100%;
  padding-top: 100%;

}




#OrderProductList .subcategories .cover img {
  border-radius: 7px;
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}




#OrderProductList ul.products .cover {
  width: 80px;
  height: 80px;
}




#OrderProductList .cover img {
  border-radius: 7px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

#OrderProductList ul.products input[type="checkbox"] {
  position: relative;
  top: 1px;
}

#OrderProductList .cat-memo {
  white-space: pre-wrap;
  line-height: 1.1;
  font-size: 0.9rem;
  color: #666;

}

/*








#OrderProductList ul .input-group {
  max-width: 120px;
  margin: 2px auto 0;
  user-select: none;
}


*/
@media screen and (min-width: 768px) {
  .modal-order-products-list .modal-content {
    width: 650px;
  }



}


@media screen and (max-width: 576px) {
  #OrderProductList ul.catalog-items li {
    flex: 1;

  }

  #OrderProductList ul.subcategories li {
    flex: 0 0 calc(50% - 10px);

  }


  #OrderProductList ul.products li {
    flex: 1 !important;

  }

}