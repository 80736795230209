html,
body {
  font-size: 100%;
}

:root {
  --gray: rgba(150, 150, 150, 1);
  --gray-9: rgba(192, 189, 196, 0.9);
  --gray-8: rgba(192, 189, 196, 0.8);
  --gray-7: rgba(192, 189, 196, 0.7);
  --gray-6: rgba(192, 189, 196, 0.6);
  --gray-5: rgba(192, 189, 196, 0.5);
  --gray-4: rgba(192, 189, 196, 0.4);
  --gray-3: rgba(192, 189, 196, 0.3);
  --gray-2: rgba(192, 189, 196, 0.2);
  --gray-1: rgba(192, 189, 196, 0.1);

  --blue: rgba(45, 175, 230, 1);
  --blue-9: rgba(45, 175, 230, 0.9);
  --blue-8: rgba(45, 175, 230, 0.8);
  --blue-7: rgba(45, 175, 230, 0.7);
  --blue-6: rgba(45, 175, 230, 0.6);
  --blue-5: rgba(45, 175, 230, 0.5);
  --blue-4: rgba(45, 175, 230, 0.4);
  --blue-3: rgba(45, 175, 230, 0.3);
  --blue-2: rgba(45, 175, 230, 0.2);
  --blue-1: rgba(45, 175, 230, 0.1);

  --yellow: rgba(254, 202, 58, 1);
  --yellow-9: rgba(254, 202, 58, 0.9);
  --yellow-8: rgba(254, 202, 58, 0.8);
  --yellow-7: rgba(254, 202, 58, 0.7);
  --yellow-6: rgba(254, 202, 58, 0.6);
  --yellow-5: rgba(254, 202, 58, 0.5);
  --yellow-4: rgba(254, 202, 58, 0.4);
  --yellow-3: rgba(254, 202, 58, 0.3);
  --yellow-2: rgba(254, 202, 58, 0.2);
  --yellow-1: rgba(254, 202, 58, 0.1);

  --green: rgba(75, 177, 102, 1);
  --green-9: rgba(75, 177, 102, 0.9);
  --green-8: rgba(75, 177, 102, 0.8);
  --green-7: rgba(75, 177, 102, 0.7);
  --green-6: rgba(75, 177, 102, 0.6);
  --green-5: rgba(75, 177, 102, 0.5);
  --green-4: rgba(75, 177, 102, 0.4);
  --green-3: rgba(75, 177, 102, 0.3);
  --green-2: rgba(75, 177, 102, 0.2);
  --green-1: rgba(75, 177, 102, 0.1);
}


.text-blue {
  color: var(--blue) !important;
}

.text-yellow {
  color: var(--yellow) !important;
}

.text-green {
  color: var(--green) !important;
}

.text-gray {
  color: var(--gray) !important;
}

body.modal-open {
  max-height: var(--viewport-height) !important;
}

.modal {
  background-color: rgba(127, 127, 127, 0.7);
}

body.embeded .modal {
  max-height: var(--viewport-height) !important;
}

body.embeded .modal-content {
  max-height: calc(var(--viewport-height) * .8) !important;
}


.modal-full-height .modal-content {
  max-height: var(--viewport-height) !important;
}

body.embeded h1 {
  display: none;
}

body.embeded .app-layout {
  padding-top: 40px;
}

dt,
dd {
  margin-bottom: 0.5rem;
}

.modal-body {
  overflow-y: scroll;
}

@media screen and (max-width: 1600px) {
  .modal-xl {
    max-width: 96%;
  }
}

body.standalone #content {
  padding-top: 120px;
  transition: all 700ms;
  padding-bottom: 200px;
}

a,
a.default {
  color: #666;
  cursor: pointer;
}

a.default {
  text-decoration: none;
}

a.default:hover {
  text-decoration: underline;
}

.pointer {
  cursor: pointer !important;
}

*:focus {
  outline: 0;
  box-shadow: none !important;
  border-color: inherit !important;
}

button {
  border: none;
  border-radius: 5px;
}

.btn-sm {
  font-size: 0.8rem !important;
}

.text-green {
  color: #026E6E !important;
}

.text-gray-9,
.text-grey-9 {
  color: #999 !important;
}

body.standalone h1 {
  font-size: calc(1rem + 1vw);
  margin-bottom: 2rem;
}

body.standalone h2 {
  font-size: calc(0.8rem + 1vw);
  margin-bottom: 2rem;
}

body.standalone h3 {
  font-size: calc(0.7rem + 1vw);
  margin-bottom: 2rem;
}

.form-label {
  font-size: 0.8rem;
}

.form-group {
  margin-bottom: 15px;
}

small,
.small {
  font-size: 0.8em !important;
}

.big {
  font-size: 1.2rem !important;
}

.nowrap {
  white-space: nowrap;
}

table thead th {
  vertical-align: middle !important;
  background-color: #bbb;
  font-size: 0.8rem;
}

table thead.spacer,
table tr.spacer,
table thead.spacer th,
table thead.spacer td,
table tbody.spacer td,
table tr.spacer td {
  height: 0 !important;
  padding: 0 !important;
  background-color: transparent !important;
  border: 0px solid transparent !important;
  box-shadow: none !important;
}

.alert-dismissible .btn-close {
  padding: 8px 12px 0 0;
  font-size: 10px !important;
}

@media screen and (max-width: 1200px) {
  .container {
    width: 96% !important;
    max-width: 96% !important;
  }

  .container {}
}

.border-light {
  border-color: #dee2e6 !important;
}

img {
  max-width: 100%;
}

.accordion {
  border: none !important;
}

.accordion,
.accordion * {
  box-shadow: none !important;
}

.accordion-item {
  border: none !important
}

.accordion-header {
  border: none !important;
}

.accordion-header button {
  border: none !important;
  background-color: #fafafa !important;
  padding: 0 0 5px 0 !important;
  color: inherit !important;
}

.accordion-body {
  margin: 0;
  padding: 0
}

.modal .modal-dialog {}

.modal .modal-content {}

.modal-title {
  color: #333;
}

.modal-lg {
  max-width: 800px !important;
  width: 96% !important;
}

.text-decoration-hover {
  text-decoration: none;
}

.text-decoration-hover:hover {
  text-decoration: underline;
}

table.table-sm th,
table.table-sm td {
  padding: 0.5rem 0.5rem;
}

.construct {
  border: 1px dotted pink;
}

.w-20 {
  width: 20%;
}

.w-40 {
  width: 40%;
}

.w-45 {
  width: 45%;
}

.fs-7 {
  font-size: 0.9rem !important;
}

.fs-8 {
  font-size: 0.8rem !important;
}

.fs-9 {
  font-size: 0.7rem !important;
}


.fs-10 {
  font-size: 0.6rem !important;
}

table.table-sm th,
table.table-sm td {
  font-size: 0.8rem !important;
}

.montserrat-alternates-thin {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.montserrat-alternates-extralight {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.montserrat-alternates-light {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.montserrat-alternates-regular {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.montserrat-alternates-medium {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.montserrat-alternates-semibold {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.montserrat-alternates-bold {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.montserrat-alternates-extrabold {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.montserrat-alternates-black {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.montserrat-alternates-thin-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.montserrat-alternates-extralight-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.montserrat-alternates-light-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.montserrat-alternates-regular-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.montserrat-alternates-medium-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.montserrat-alternates-semibold-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.montserrat-alternates-bold-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.montserrat-alternates-extrabold-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.montserrat-alternates-black-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 900;
  font-style: italic;
}

body.embeded h1,
body.embeded h2,
body.embeded h3 {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 700;
  font-style: normal;
}

body.embeded h2 {
  font-size: 1.1rem;
  margin-bottom: 25px;
  color: var(--blue);
}

body.embeded h3 {
  font-size: 0.9rem;
  margin-bottom: 25px;
  color: #666;
}

input.form-control:focus {
  border: 1px solid var(--blue) !important;
  background-color: var(--blue-2);
}

.btn-blue {
  background-color: var(--blue-9);
  color: white
}

.btn-outline-blue {
  background-color: white;
  color: var(--blue);
  border: 1px solid var(--blue);
}

.btn-outline-blue:hover {
  background-color: var(--blue-9);
  color: white
}




.btn-blue:hover {
  background-color: var(--blue);
}

.btn-yellow {
  background-color: var(--yellow-9);
}

.btn-yellow:hover {
  background-color: var(--yellow);
}


.btn-green {
  background-color: var(--green-9);
}

.btn-green:hover {
  background-color: var(--green);
}