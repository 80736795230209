#AdminOrderModalEditItem {}

#AdminOrderModalEditItem .pickups-list {
  font-size: smaller;
  list-style: none;
  margin: 0;
  padding: 0;
}

#AdminOrderModalEditItem .pickups-list li {}

#AdminOrderModalEditItem .pickups-list li>div:hover {
  background-color: rgb(var(--bs-light-rgb)) !important;
}