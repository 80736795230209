.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

:root {
  --collecte: #046FB5;
  --restitution: #D9F371;
  --paid: #198754;
  --unpaid: #dc3545;
  --partial: #ffc107;
  --active: #dc3545;
  --pending: #dc3545;
  --account: rgba(111, 165, 226, 0.8);
  /* commandes des clients en compte : ordPaymentStatus =account */
}

.collecte-bg {
  background-color: var(--collecte);
  color: white;
}

.restitution-bg {
  background-color: var(--restitution);
  color: #666;
}

.text-red {
  color: red !important;
}


.order-pending {
  color: var(--partial) !important;
}

.order-canceled {
  color: #000 !important;
}

.order-handled {
  color: #0a58ca !important;
}

.order-completed {
  color: var(--paid) !important;
}