#order-details {
  font-size: 0.9rem;
}


#order-details .section {
  margin-bottom: 1.2rem;
}

.accordion-header button,
#order-details label {
  font-weight: bold;
  margin-bottom: 0.2rem;
  font-size: 0.9rem;
}

#order-details label {
  display: block;
}

#order-details #CartDetails table {
  margin-bottom: 15px !important;
}

#order-details .order-infos table td {
  border: none !important
}


#order-details .uploader {
  width: 25px;
}

#order-details .uploader button.btn-upload {
  background-color: rgb(255, 193, 7);
  color: #111;
  font-size: 0.8rem;

  padding-top: 0 !important;
}


#order-details .uploader .alert {
  display: none
}