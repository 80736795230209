.nav-tabs {
  border-bottom: 1px solid #ffde00 !important;
}

.nav-tabs a {
  color: #999;
  user-select: none;
}

.nav-tabs a:not(.active):hover {
  color: #ffde00 !important;

}


.nav-tabs a.active {
  background-color: #ffe537 !important;
  border-color: #ffde00 !important;

}

.tab-contents {
  border: 1px solid #ffde00;
  border-top: none;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #fafafa;
  padding: 25px;
}