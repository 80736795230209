.admin-pickup-modal h6 {
  font-size: 0.95rem;
  margin: 0;
  padding: 0;
}



#AdminPickupModalCities .table-wrapper {
  height: 50vh;
  overflow-y: scroll;
}



#AdminPickupModalCities .row,
#AdminPickupModalCities .row>div {
  border: 0px salmon solid;
}

#AdminPickupModalCities td {
  vertical-align: middle;
}

#AdminPickupModalCities td.checkbox {
  width: 25px;
  text-align: center;
  padding-top: 5px;
}

#AdminPickupModalCities .form-control {
  padding: 0.05rem 0.5rem;
  font-size: 0.7rem;
}

#AdminPickupModalOccurrences .occurrence {}

#AdminPickupModalOccurrences .occurrence-header {
  border-bottom: 1px solid white;
}

#AdminPickupModalOccurrences .badge {
  font-size: 0.65rem !important;
  font-weight: normal !important;
  padding: 0.5em 0.2em !;

}


#AdminPickupModalOccurrences .occurrence-body {}