.calendar .fc-button-primary {
  background-color: #ffe850;
  border: none;
  color: #222;
}

.calendar .fc-toolbar-title {
  text-transform: capitalize;
}

.calendar .fc-button-primary:hover {
  background-color: #ffde00;
  border: none;
}

.calendar .fc-toolbar-chunk {
  flex: 0 0 33.333333%;
  width: 33.3333333%;
}

.calendar .fc-toolbar-chunk:nth-child(2) {
  text-align: center;
}

.calendar .fc-toolbar-chunk:nth-child(3) {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.calendar .fc-direction-ltr .fc-daygrid-week-number {
  background-color: transparent;
  font-size: 0.7rem;
  text-decoration: none;
  position: absolute;
  top: 3px;
  left: 0
}

.calendar .fc-scrollgrid-sync-table {
  /*  height: auto !important; */
}

.calendar .fc-daygrid-event {}


.calendar .fc-daygrid-event-harness {

  font-size: 0.9rem;
  max-width: 100%;
  overflow: hidden;


}

.calendar .fc .fc-daygrid-body-balanced .fc-daygrid-day-events {
  position: relative;

}

.calendar .fc-theme-standard td,
.calendar .fc-theme-standard th {
  height: 2px;
}


.calendar .fc-direction-ltr .fc-daygrid-event.fc-event-end,
.calendar .fc-direction-rtl .fc-daygrid-event.fc-event-start {
  /* margin-right: 0; */
}


.calendar .fc .fc-daygrid-day.fc-day-today {
  background-color: transparent;


}

.calendar .fc .fc-daygrid-day-number {


  /*
    font-size: 0.75rem;
  
  padding-bottom: 3px;
  padding-top: 2px;
  font-weight: bold;
  line-height: 1;
  */
  text-decoration: none;
  width: 20px;
  line-height: 20px;
  padding: 0 !important;
  margin-top: 3px;

  text-align: center;

  font-size: 0.70rem;
}

.calendar .fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
  background-color: #ffe850;

  border-radius: 50%;


}

.calendar .fc .fc-daygrid-day-top {
  justify-content: center;
  flex-direction: row;
}