#backoffice-header {
  background-color: #eee;
  padding: 0 10px;
}

#backoffice-header .row {

  align-items: center;
}

#backoffice-header .col-center a {
  padding: 5px 10px;
  display: inline-block;
  border-right: 1px solid white;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.9rem;
}

#backoffice-header .col-center a:hover {
  text-decoration: underline;
}

#backoffice-header .col-center a:last-child {


  border-right: 0px solid white;
}